(function() {

	var timeoutSeconds = 30,
		timeout = timeoutSeconds * 1000,
		fonts = [
		[ "GrotzecCond", 400, 'normal' ],
		[ "GrotzecCond", 400, 'italic' ]
	];

	Promise.all( function() {

		var loaders = [];
		fonts.forEach( function( font ) {
			loaders.push(
				new FontFaceObserver( font[0], { weight: font[1], style: font[2] } ).load( null, timeout )
			);
		});

		return loaders;

	}() ).then(function() {
			document.documentElement.className += " fontface";
			sessionStorage.setItem('fontfaceLoaded', APP.siteVersion);
		}, function() {
			document.documentElement.className += " fontface--timeout";
		});
})();
